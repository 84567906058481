@media only screen and (max-width: 800px) {
  .navbar-right-options {
    display: none !important;
  }
  .navbar-right-menubar {
    display: block !important;
  }
  .navbar-container {
    position: inherit !important;
    width: 100%;
  }
}

.navbar-container {
  color: white;
  width: 95vw;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  position: fixed;
}

.navbar-left {
  width: 30%;
}

.navbar-left > a {
  justify-content: flex-start;
}

.navbar-right-options {
  color: var(--section-grey-color);
  width: 40%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 800px) {
  .navbar-right-options {
    display: none !important;
  }
  .navbar-right-menubar {
    display: block !important;
  }
  .navbar-container {
    position: inherit !important;
    width: 100%;
  }
}

.navbar-container {
  color: white;
  width: 95vw;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  position: fixed;
}

.navbar-left {
  width: 30%;
}

.navbar-left > a {
  justify-content: flex-start;
}

.navbar-right-options{
 
  color: var(--section-grey-color);
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.navbar-right-options > a:hover {
  color: var(--section-color) !important;
}

.navbar-right-menubar {
  display: none;
}

.navbar-menu-options {
  display: flex;
  justify-content: space-around;
  font-size: 2rem;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0%;
  right: 0%;
  width: 100vw;
  height: 85vh;
  background-color: #121212;
  z-index: 1;
}

.navbar-menu-options > svg {
  font-size: 3rem;
}
}

.navbar-right-options > a:hover {
  color: var(--section-color) !important;
}

.navbar-right-menubar {
  display: none;
}

.navbar-menu-options {
  display: flex;
  justify-content: space-around;
  font-size: 2rem;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0%;
  right: 0%;
  width: 100vw;
  height: 85vh;
  background-color: #121212;
  z-index: 1;
}

.navbar-menu-options > svg {
  font-size: 3rem;
}